/**
 * Component for our main Navigation sidebar
 * in the dashboard.
 *
 * @component
 */

import { Menu } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  SolidIconBarChart,
  SolidIconBoxPackage,
  SolidIconBoxSetting,
  SolidIconBriefcase,
  SolidIconEarthSetting,
  SolidIconEmailSetting,
  SolidIconHome,
  SolidIconPackage,
} from '../../../components/CustomReturnsIcons';
import { SubscriptionContext } from '../../../contexts/SubscriptionContext';

const { SubMenu } = Menu;
const Navigation = withRouter((props) => {
  const context = useContext(SubscriptionContext);
  const { location } = props;
  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    const pathname = location.pathname;
    setSelectedKeys([pathname]);

    const newOpenKeys = [];
    if (pathname.includes('/dashboard/analytics')) {
      newOpenKeys.push('analytics-settings');
    }
    if (pathname.includes('/dashboard/settings')) {
      newOpenKeys.push('return-settings');
    }
    if (pathname.includes('/dashboard/brand')) {
      newOpenKeys.push('brand-settings');
    }
    if (pathname.includes('/dashboard/email')) {
      newOpenKeys.push('email-settings');
    }
    if (pathname.includes('/dashboard/connect') 
      && (!pathname.includes('shopifyappstore') && !pathname.includes('shopifybilling'))) 
    {
      newOpenKeys.push('integration-settings');
    }
    if (pathname.includes('/dashboard/account')) {
      newOpenKeys.push('account-settings');
    }
    setOpenKeys(newOpenKeys);
  }, [location.pathname]);

  const handleMenuClick = (e) => {
    setSelectedKeys([e.key]);
  };

  if (location.pathname.includes('/dashboard/settings/exchanges')) {
    selectedKeys.push('/dashboard/settings/exchanges');
  }

  if (location.pathname.includes('/dashboard/analytics/trends')) {
    selectedKeys.push('/dashboard/analytics/trends');
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        rowGap: '24px',
      }}
    >
      <div>
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          onClick={handleMenuClick}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
        >
          <Menu.Item key="/dashboard">
            <Link to="/dashboard">
              <span className=' flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs'>
                <SolidIconHome size="sm" />
                <span className="TextHeading TextHeading--xxxs u-marginBottom--none">Home</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/returns">
            <Link to="/dashboard/returns">
              <span className=' flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs'>
                <SolidIconBoxPackage size="sm" />
                <span className="TextHeading TextHeading--xxxs u-marginBottom--none">Returns</span>
              </span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="analytics-settings"
            title={
              <span className=' flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs'>
                <SolidIconBarChart size="sm" />
                <span className="TextHeading TextHeading--xxxs u-marginBottom--none">Analytics</span>
              </span>
            }
          >
            <Menu.Item key="/dashboard/analytics/dashboard">
              <Link to="/dashboard/analytics/dashboard">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Overview</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/analytics/reports/reasons/by-product">
              <Link to="/dashboard/analytics/reports/reasons/by-product">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Reports</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/analytics/trends">
              <Link to="/dashboard/analytics/trends">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Trends</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/analytics/customer">
              <Link to="/dashboard/analytics/customer">
                <div
                  className=" flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs"
                >
                  <div className="TextBody TextBody--xxs u-marginBottom--none u-marginRight--xxs">Customers</div>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/analytics/export">
              <Link to="/dashboard/analytics/export">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Data export</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="return-settings"
            title={
              <span className=' flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs'>
                <SolidIconBoxSetting size="sm" />
                <span className="TextHeading TextHeading--xxxs u-marginBottom--none">Configuration</span>
              </span>
            }
          >
            <Menu.Item key="/dashboard/settings">
              <Link to="/dashboard/settings">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Returns</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/settings/exchanges">
              <Link to="/dashboard/settings/exchanges">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Exchanges</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/settings/carrires">
              <Link to="/dashboard/settings/carrires">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Prepaid Labels</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/settings/refunds">
              <Link to="/dashboard/settings/refunds">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Refunds</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/settings/incentives">
              <Link to="/dashboard/settings/incentives">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Incentives</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/settings/inventory">
              <Link to="/dashboard/settings/inventory">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Inventory Management</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/settings/rules">
              <Link to="/dashboard/settings/rules">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Advanced Rules</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="/dashboard/settings/sync">
              <Link to="/dashboard/settings/sync">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Shopify Syncing</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/settings/payment">
              <Link to="/dashboard/settings/payment">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Payments</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="brand-settings"
            title={
              <span className=' flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs'>
                <SolidIconEarthSetting size="sm" />
                <span className="TextHeading TextHeading--xxxs u-marginBottom--none u-marginRight--xxs">Returns portal</span>
              </span>
            }
          >
            <Menu.Item key="/dashboard/brand">
              <Link to="/dashboard/brand">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Customizations</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/brand/custom-domain">
              <Link to="/dashboard/brand/custom-domain">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Own domain</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/brand/translations">
              <Link to="/dashboard/brand/translations">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Translations</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="email-settings"
            title={
              <span className=' flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs'>
                <SolidIconEmailSetting size="sm" />
                <span className="TextHeading TextHeading--xxxs u-marginBottom--none">Notifications</span>
              </span>
            }
          >
            <Menu.Item key="/dashboard/email/admin">
              <Link to="/dashboard/email/admin">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Staff emails</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/email">
              <Link to="/dashboard/email">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Customer emails</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="integration-settings"
            title={
              <span className=' flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs'>
                <SolidIconPackage size="sm" />
                <span className="TextHeading TextHeading--xxxs u-marginBottom--none">Integrations</span>
              </span>
            }
          >
            <Menu.Item key="/dashboard/connect/platform">
              <Link to="/dashboard/connect/platform">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Platforms</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/connect/courier">
              <Link to="/dashboard/connect/courier">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Carriers</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>

      <div>
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          onClick={handleMenuClick}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
        >
          <SubMenu
            key="account-settings"
            title={
              <span className=' flex-row flex-row--noMargin flex-middle-xxs u-flexWrap--nowrap u-columnGap--xs'>
                <SolidIconBriefcase size="sm" />
                <span className="TextHeading TextHeading--xxxs u-marginBottom--none">Account</span>
              </span>
            }
          >
            {context && context.directBilling ? (
              <Menu.Item key="/dashboard/account/plan" className="navAccount">
                <Link to="/dashboard/account/plan">
                  <span className="TextBody TextBody--xxs u-marginBottom--none">Your plan</span>
                </Link>
              </Menu.Item>
            ) : (
              <Menu.Item key="/dashboard/account/profile" className="navAccount">
                <Link to="/dashboard/account/profile">
                  <span className="TextBody TextBody--xxs u-marginBottom--none">Your plan</span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="/dashboard/account/api">
              <Link to="/dashboard/account/api">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Developers</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/account/audit-log">
              <Link to="/dashboard/account/audit-log">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Audit log</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/tutorials">
              <a
                href="https://support.richreturns.io/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="TextBody TextBody--xxs u-marginBottom--none">Tutorials & FAQ</span>
              </a>
            </Menu.Item>
            <Menu.Item key="/dashboard/logout">
              <Link to="/dashboard/logout">
                <span className="TextBody TextBody--xxs u-marginBottom--none">Log out</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </div>
  );
});

export default Navigation;
