/**
 * Component for Multiple Rule Conditions settings
 *
 * @component
 */

import {
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import {
  frequentlyUsedCountries,
  nonFrequentlyUsedCountries,
} from '../../../../constants/constants';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;

class RuleConditionSection extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      showDateInput: false,
      listCollection: [],
    };
  }
  async componentDidMount() {
    let trigger, value;
    trigger = this.props.props?.selectedRule?.trigger;
    value = this.props.props?.selectedRule?.value;
    if (this.props.secondary) {
      trigger = this.props.props?.selectedRule?.secondaryTrigger;
      value = this.props.props?.selectedRule?.secondaryValue;
    }

    try {
      const response = await axios.get(
        '/api/returnSettings/rules/listCollections'
      );
      this.setState({
        listCollection: response.data.listCollections,
      });
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(
        '/api/returnSettings/rules/listCollections'
      );
      this.setState({
        listCollection: response.data.listCollections,
      });
    } catch (error) {
      console.error(error);
    }

    if (trigger === 'orderDateRange') {
      const datesObject = JSON.parse(value);
      this.setState({
        startDate: moment(datesObject.startDate),
        endDate: moment(datesObject.endDate),
      });
    }
    this.setState({
      showDateInput: true,
    });
  }
  getCountriesList = () => {
    let countriesList = [];
    frequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option key={`frequentlyUsedCountries${country.code}`} value={country.code}>{country.name}</Option>);
    });

    const disabledoption = (
      <Option key="disabled" value="disabled" disabled={true}>
        ---------
      </Option>
    );
    countriesList.push(disabledoption);
    nonFrequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option key={`nonFrequentlyUsedCountries${country.code}`} value={country.code}>{country.name}</Option>);
    });

    return countriesList;
  };

  checkIfDisabled = (value) => {
    const { secondary, props } = this.props;
    const selectedRule = props?.selectedRule;
    let disabled = false;
    if (secondary && selectedRule?.trigger === value) {
      disabled =
        selectedRule?.secondaryConditionOperator === 'AND' ? true : false;
    }
    return disabled;
  };

  changeTrigger = (e) => {
    this.props.props.updateInput(
      e,
      this.props.secondary ? 'secondaryTrigger' : 'trigger'
    )
    if (e === 'originalOrderValue') {
      this.props.props.updateInput(100.0, this.props.secondary ? 'secondaryValue' : 'value');
    }
    if (e === 'productCollection') {
      this.props.props.updateInput(null, this.props.secondary ? 'secondaryValue' : 'value');
    }
  };

  onChange = (dates, dateStrings) => {
    if (dateStrings[0] || dateStrings[1]) {
      this.setState(
        {
          startDate: dateStrings[0] && moment(dateStrings[0]),
          endDate: dateStrings[1] && moment(dateStrings[1]),
        },
        () => {
          const dateString = JSON.stringify({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          });
          this.props.props.updateInput(
            undefined,
            this.props.secondary ? 'secondaryValue' : 'value',
            dateString
          );
        }
      );
    } else {
      this.props.props.updateInput(
        undefined,
        this.props.secondary ? 'secondaryValue' : 'value'
      );
    }
  };

  getValueContry = (country) => {
    const countryNames = [
      ...frequentlyUsedCountries,
      ...nonFrequentlyUsedCountries,
    ].map((i) => i.code);

    if (countryNames.includes(country)) return country;
    return countryNames[0];
  };

  render() {
    let trigger, operator, value;
    trigger = this.props.props?.selectedRule?.trigger;
    operator = this.props.props?.selectedRule?.operator;
    value = this.props.props?.selectedRule?.value;

    if (this.props.secondary) {
      trigger = this.props.props?.selectedRule?.secondaryTrigger;
      operator = this.props.props?.selectedRule?.secondaryOperator;
      value = this.props.props?.selectedRule?.secondaryValue;
    }
    const { listCollection } = this.state;

    const listCollectionOptions = listCollection.map((collection) => (
      <Option key={collection.id} value={collection.id}>
        {collection.title}
      </Option>
    ));
    return (
      <React.Fragment>
        <div className="flex-row u-rowGap--sm u-width--100">
          <div className="flex-col-xxs-12">
            <h4>Trigger</h4>
            <Select
              defaultValue={
                this.props.props.selectedRule ? trigger : 'orderCountry'
              }
              style={{ width: '100%' }}
              placeholder="Select trigger"
              onChange={this.changeTrigger}
              suffixIcon={<span>Trigger</span>}
            >
              <Option key="disabled" value="disabled" disabled={true}>
                --- Basic rules based on order information ---
              </Option>
              <Option
                key="orderCountry"
                value="orderCountry"
                disabled={this.checkIfDisabled('orderCountry')}
              >
                Order Country
              </Option>
              <Option
                key="orderTag"
                value="orderTag"
                disabled={this.checkIfDisabled('orderTag')}
              >
                Order Tag
              </Option>
              <Option
                key="orderDays"
                value="orderDays"
                disabled={this.checkIfDisabled('orderDays')}
              >
                Days since delivery date
              </Option>
              <Option
                key="orderDateRange"
                value="orderDateRange"
                disabled={this.checkIfDisabled('orderDateRange')}
              >
                Order Date Range
              </Option>
              <Option
                key="discountCode"
                value="discountCode"
                disabled={this.checkIfDisabled('discountCode')}
              >
                Discount code
              </Option>
              <Option
                key="discountPercentage"
                value="discountPercentage"
                disabled={this.checkIfDisabled('discountPercentage')}
              >
                Discount in Percent of Order Total
              </Option>
              <Option
                key="originalOrderValue"
                value="originalOrderValue"
                disabled={this.checkIfDisabled('originalOrderValue')}
              >
                {' '}
                Order Total
              </Option>
              <Option
                key="paymentGateway"
                value="paymentGateway"
                disabled={this.checkIfDisabled('paymentGateway')}
              >
                Payment Gateway
              </Option>
              <Option key="disabled2" value="disabled2" disabled={true}>
                --- Rules based on customer input ---
              </Option>
              <Option
                key="selectedAction"
                value="selectedAction"
                disabled={this.checkIfDisabled('selectedAction')}
              >
                Selected return action (e.g. Refund, Exchange, etc)
              </Option>
              <Option
                key="selectedReason"
                value="selectedReason"
                disabled={this.checkIfDisabled('selectedReason')}
              >
                Selected return reason
              </Option>
              <Option key="disabled3" value="disabled3" disabled={true}>
                --- Rules based on Products ---
              </Option>
              <Option
                key="productTag"
                value="productTag"
                disabled={this.checkIfDisabled('productTag')}
              >
                Product Tag
              </Option>
              <Option
                key="productSKU"
                value="productSKU"
                disabled={this.checkIfDisabled('productSKU')}
              >
                Product SKU
              </Option>
              <Option
                key="productDiscount"
                value="productDiscount"
                disabled={this.checkIfDisabled('productDiscount')}
              >
                Discount in Percent of Product Price
              </Option>
              <Option
                key="productCollection"
                value="productCollection"
                disabled={this.checkIfDisabled('productCollection')}
              >
                Product Collections
              </Option>
            </Select>
          </div>
          <div className="flex-col-xxs-12">
            <h4>Operator</h4>
            {[
              'orderCountry',
              'orderTag',
              'selectedAction',
              'selectedReason',
              'discountCode',
              'paymentGateway',
              'productTag',
              'productSKU',
            ].includes(trigger) ? (
                <Select
                  defaultValue={
                    this.props.props.selectedRule &&
                  ['is', 'isNot'].includes(operator)
                      ? operator
                      : 'is'
                  }
                  style={{ width: '100%' }}
                  placeholder="Select operator"
                  onChange={(e) =>
                    this.props.props.updateInput(
                      e,
                      this.props.secondary ? 'secondaryOperator' : 'operator'
                    )
                  }
                  suffixIcon={<span>Operator</span>}
                >
                  <Option key="is" value="is">is</Option>
                  <Option key="isNot" value="isNot">is not</Option>
                </Select>
              ) : null}
            {['productCollection'].includes(trigger) ? (
              <Select
                defaultValue={
                  this.props.props.selectedRule &&
                  ['in', 'notIn'].includes(operator)
                    ? operator
                    : 'in'
                }
                style={{ width: '100%' }}
                placeholder="Select operator"
                onChange={(e) =>
                  this.props.props.updateInput(
                    e,
                    this.props.secondary ? 'secondaryOperator' : 'operator'
                  )
                }
                suffixIcon={<span>Operator</span>}
              >
                <Option key={1} value="in">
                  In
                </Option>
                <Option key={2} value="notIn">
                  Not in
                </Option>
              </Select>
            ) : null}
            {['orderDateRange'].includes(trigger) && (
              <Select
                defaultValue={
                  this.props.props.selectedRule &&
                  ['is', 'isNot'].includes(operator)
                    ? operator
                    : undefined
                }
                style={{ width: '100%' }}
                placeholder="Select operator"
                onChange={(e) =>
                  this.props.props.updateInput(
                    e,
                    this.props.secondary ? 'secondaryOperator' : 'operator'
                  )
                }
                suffixIcon={<span>Operator</span>}
              >
                <Option key={1} value="is">is</Option>
                <Option key={2} value="isNot">is not</Option>
              </Select>
            )}
            {[
              'orderDays',
              'originalOrderValue',
              'discountPercentage',
              'productDiscount',
            ].includes(trigger) ? (
                <Select
                  defaultValue={
                    this.props.props.selectedRule &&
                  ['less', 'more'].includes(operator)
                      ? operator
                      : 'less'
                  }
                  style={{ width: '100%' }}
                  placeholder="Select operator"
                  onChange={(e) =>
                    this.props.props.updateInput(
                      e,
                      this.props.secondary ? 'secondaryOperator' : 'operator'
                    )
                  }
                  suffixIcon={<span>Operator</span>}
                >
                  <Option key={1} value="less">less than</Option>
                  <Option key={2} value="more">more than</Option>
                </Select>
              ) : null}
          </div>
          <div className="flex-col-xxs-12">
            <h4>Value</h4>
            <Row
              type="flex"
              justify="center"
              align="top"
              style={{ marginBottom: 20, textAlign: 'center', width: '100%' }}
            >
              <Col span={24}>
                {['orderDateRange'].includes(trigger) ? (
                  <>
                    {(!this.props.props?.selectedRule?.name ||
                      (this.props.props?.selectedRule?.name &&
                        this.state.showDateInput)) && (
                      <RangePicker
                        style={{ width: '100%', marginTop: 10 }}
                        defaultValue={[
                          this.state.startDate,
                          this.state.endDate,
                        ]}
                        ranges={{
                          Today: [moment(), moment()],
                          Yesterday: [
                            moment().subtract(1, 'day').startOf('day'),
                            moment().subtract(1, 'day').endOf('day'),
                          ],
                          'Last Week': [
                            moment().subtract(1, 'week').startOf('week'),
                            moment().subtract(1, 'week').endOf('week'),
                          ],
                          'This Month': [
                            moment().startOf('month'),
                            moment().endOf('month'),
                          ],
                          'Last Month': [
                            moment().subtract(1, 'month').startOf('month'),
                            moment().subtract(1, 'month').endOf('month'),
                          ],
                          'Last Quarter': [
                            moment().subtract(3, 'months').startOf('quarter'),
                            moment().subtract(3, 'months').endOf('quarter'),
                          ],
                        }}
                        onChange={this.onChange}
                        onCalendarChange={(dateArray) => {
                          const endDateSelected = dateArray[1] != null;
                          if (endDateSelected) {
                            return this.setState({
                              date: undefined,
                            });
                          }

                          const startDateSelected = dateArray[0];
                          if (startDateSelected) {
                            this.setState({
                              date: dateArray[0],
                            });
                          }
                        }}
                      />
                    )}
                  </>
                ) : null}
                {[
                  'orderTag',
                  'selectedReason',
                  'discountCode',
                  'paymentGateway',
                  'productTag',
                  'productSKU',
                ].includes(trigger) ? (
                    <Input
                      defaultValue={
                        this.props.props.selectedRule ? value : undefined
                      }
                      style={{ width: '100%' }}
                      onChange={(e) =>
                        this.props.props.updateInput(
                          e,
                          this.props.secondary ? 'secondaryValue' : 'value'
                        )
                      }
                    />
                  ) : null}

                {[
                  'orderDays',
                  'discountPercentage',
                  'productDiscount',
                ].includes(trigger) ? (
                    <InputNumber
                      defaultValue={
                        this.props.props.selectedRule && value && !isNaN(value)
                          ? value
                          : 1
                      }
                      style={{ width: '100%' }}
                      min={1}
                      precision={0}
                      onChange={(e) =>
                        this.props.props.updateInput(
                          e,
                          this.props.secondary ? 'secondaryValue' : 'value'
                        )
                      }
                    />
                  ) : null}

                {['originalOrderValue'].includes(trigger) ? (
                  <InputNumber
                    defaultValue={
                      this.props.props.selectedRule && value ? value : 100.0
                    }
                    style={{ width: '100%' }}
                    min={0}
                    precision={2}
                    onChange={(e) =>
                      this.props.props.updateInput(
                        e,
                        this.props.secondary ? 'secondaryValue' : 'value'
                      )
                    }
                  />
                ) : null}
                {trigger === "productCollection" ? (
                  <Select
                    defaultValue={this.props.props.selectedRule && value ? parseInt(value) : undefined}
                    placeholder="Choose collection"
                    onChange={(e) => this.props.props.updateInput(e, this.props.secondary ? "secondaryValue" : "value")}
                    style={{ width: "100%" }}
                  >
                    {listCollectionOptions}
                  </Select>
                ) : null}
                {trigger === 'selectedAction' ? (
                  <Select
                    defaultValue={
                      this.props.props.selectedRule &&
                      [
                        'refundToOriginal',
                        'refundToCredit',
                        'refundToExchange',
                        'advancedExchange',
                        'storeWideExchange',
                        'refundToCustom1',
                        'refundToCustom2',
                        'refundToCustom3',
                      ].includes(value)
                        ? value
                        : undefined
                    }
                    placeholder="Select Action"
                    onChange={(e) =>
                      this.props.props.updateInput(
                        e,
                        this.props.secondary ? 'secondaryValue' : 'value'
                      )
                    }
                    style={{ width: '100%' }}
                  >
                    <Option key="refundToOriginal" value="refundToOriginal">Refund</Option>
                    <Option key="refundToCredit" value="refundToCredit">Store Credit</Option>
                    <Option key="refundToExchange" value="refundToExchange">Exchange</Option>
                    {this.context.featureAdvancedExchanges ? (
                      <Option key="advancedExchange" value="advancedExchange">
                        Collection Exchange
                      </Option>
                    ) : null}
                    {this.context.featureStoreWideExchange ? (
                      <Option key="storeWideExchange" value="storeWideExchange">
                        Store-Wide Exchange
                      </Option>
                    ) : null}
                    <Option key="refundToCustom1" value="refundToCustom1">Custom Option 1</Option>
                    <Option key="refundToCustom2" value="refundToCustom2">Custom Option 2</Option>
                    <Option key="refundToCustom3" value="refundToCustom3">Custom Option 3</Option>
                  </Select>
                ) : null}
                {this.props.props.selectedRule && trigger === 'orderCountry' ? (
                  <Select
                    defaultValue={
                      this.props.props.selectedRule && value
                        ? this.getValueContry(value)
                        : undefined
                    }
                    placeholder="Select country"
                    onChange={(e) =>
                      this.props.props.updateInput(
                        e,
                        this.props.secondary ? 'secondaryValue' : 'value'
                      )
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    suffixIcon={<span>Country</span>}
                  >
                    {this.getCountriesList()}
                  </Select>
                ) : null}
              </Col>
            </Row>

            {this.props.props.selectedRule &&
            ['paymentGateway'].includes(trigger) ? (
                <Row
                  type="flex"
                  justify="space-around"
                  align="top"
                  style={{ marginTop: 20, width: '100%' }}
                >
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Card size="small" title="Rule info">
                      <p>
                        <Text>
                          <strong>Important:</strong> You need to put the
                        "technical value" in this field, e.g. for Shopify
                        Payments it is shopify_payments.{' '}
                          <a
                            href="https://support.richreturns.io/en/articles/9272461-list-of-payment-gateway-names"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                          Click here for a list
                          </a>{' '}
                        of commonly used payment gateways and their technical
                        values.
                        </Text>
                      </p>
                    </Card>
                  </Col>
                </Row>
              ) : null}

            {this.props.props.selectedRule &&
            ['originalOrderValue'].includes(trigger) ? (
                <Row
                  type="flex"
                  justify="space-around"
                  align="top"
                  style={{ marginTop: 20, width: '100%' }}
                >
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Card size="small" title="Rule info">
                      <p>
                        <Text>
                          <strong>Important:</strong> Value must be an amount in
                        your Store's default currency, e.g. USD
                        </Text>
                      </p>
                    </Card>
                  </Col>
                </Row>
              ) : null}

            {this.props.props.selectedRule &&
            ['discountPercentage', 'productDiscount'].includes(trigger) ? (
                <Row
                  type="flex"
                  justify="space-around"
                  align="top"
                  style={{ marginTop: 20, width: '100%' }}
                >
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Card size="small" title="Rule info">
                      <p>
                        <Text>
                          <strong>Note:</strong> Value in percent (e.g. 21) of
                        total{' '}
                          {trigger === 'productDiscount'
                            ? 'product value'
                            : 'order value of the original order'}
                        . If you use "less than" be aware that this also
                        includes{' '}
                          {trigger === 'productDiscount'
                            ? 'products '
                            : 'orders '}
                        without a discount at all.
                        </Text>
                      </p>
                    </Card>
                  </Col>
                </Row>
              ) : null}

            {this.props.props.selectedRule &&
            ['selectedAction', 'selectedReason'].includes(trigger) ? (
                <Row
                  type="flex"
                  justify="space-around"
                  align="top"
                  style={{ marginTop: 20, width: '100%' }}
                >
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Card size="small" title="Rule info">
                      <p>
                        <Text>
                          <strong>1.</strong> This rule is applied as soon as the
                        condition is fulfilled for at least one product in the
                        return.
                        </Text>
                      </p>
                      <p>
                        <Text>
                          <strong>2.</strong> This rule is applied in response to
                        customer input. Therefore it will overwrite other basic
                        rules on the properties you set. It will not overwrite
                        other rules of the same type.
                        </Text>
                      </p>
                    </Card>
                  </Col>
                </Row>
              ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RuleConditionSection;
