/**
 * Component to register GorgiasModal integration.
 *
 * @component
 */

import React from "react";
import { Modal, Input, message, Tooltip } from "antd";
import axios from "axios";

class GorgiasModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: "",
      username: "",
      propertyUrl: "",
      confirmLoading: false,
      findCredentials: false
    };
  }

  handleInputChange = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  componentDidMount = async () => {
    try {
      let response = await axios.get("/api/integrations/gorgias");
      if (response.data) {
        const { accessToken, username, propertyUrl } = response.data;
        return this.setState({
          apiKey: accessToken,
          username,
          propertyUrl,
          findCredentials: true,
        })
      } else {
        return null;
      }      
    } catch (err) {
      return message.error("something went wrong while loading the integrations details please try again.", 5);
    }
  }

  handleOk = async () => {
    if (!this.state.apiKey && !this.state.username ) {
      return message.error("please fill all the fields.", 5);
    }
    this.setState({
      confirmLoading: true,
    });
    try {
      let propertyUrl = this.state.propertyUrl.replace("http://","").replace("https://","").replace("www","").replace(".gorgias.com","").replace(".","").trim();
      let response = await axios.post("/api/integrations/gorgias", {
        apiKey: this.state.apiKey,
        username: this.state.username,
        propertyUrl
      });
      if (response.data.error) {
        this.props.modalVisible(null);
        this.setState({
          confirmLoading: false,
          apiKey: "",
          username: "",
          propertyUrl: "",
        });
        return message.error("something went wrong please try again.", 5);
      }
      this.props.modalVisible(null);
      this.setState({
        confirmLoading: false,
        apiKey: "",
        username: "",
        propertyUrl: "",
      });
      trackFSEvent("Connect app", {
        feature: "Integrations",
        integratedApp: "gorgias"
      });
      return message.success(response.data.message, 5);
    } catch (err) {
      this.props.modalVisible(null);
      this.setState({
        confirmLoading: false,
        apiKey: "",
        username: "",
        propertyUrl: "",
      });
      return message.error("something went wrong please try again", 5);
    }
  };

  handleBlur = () => {
    if (this.state.propertyUrl) {
      this.setState({findCredentials: true})
    } else {
      this.setState({findCredentials: false})
    }
  }


  render() {
    return (
      <Modal
        title="Set up your Gorgias integration"
        visible={true}
        onOk={this.handleOk}
        confirmLoading={this.state.confirmLoading}
        onCancel={()=>this.props.modalVisible(null)}
      >
        <span><strong>Base API URL</strong></span>
        <Tooltip title="For 'yourcompany.gorgias.com', the Base API URL you enter in this field is 'yourcompany'">
          <Input
            addonBefore="https://" 
            addonAfter=".gorgias.com"
            size="large"
            value={this.state.propertyUrl}
            placeholder="Enter your Gorgias base API URL"
            onBlur={()=> this.handleBlur()}
            onChange={(e) => this.handleInputChange("propertyUrl", e)}
          />
          {
            this.state.findCredentials ? <>You can find your API-Key and username for your Gorgias account <a href={`https://${this.state.propertyUrl}.gorgias.com/app/settings/api`} target="_blank" rel="noopener noreferrer">here</a></> : ""
          }
        </Tooltip>
        <br />
        <br />
        <span><strong>Username / email address</strong></span>
        <Input
          style={{marginTop: "1%"}}
          size="large"
          value={this.state.username}
          placeholder="Enter your Gorgias Username (your email address)"
          onChange={(e) => this.handleInputChange("username", e)}
        />
        <br />
        <br />
        <span><strong>API Key</strong></span>
        <Input
          style={{marginTop: "1%"}}
          size="large"
          value={this.state.apiKey}
          placeholder="Enter your Gorgias API Key"
          onChange={(e) => this.handleInputChange("apiKey", e)}
        />
      </Modal>
    );
  }
}

export default GorgiasModal;
