/**
 * Component for main Returns settings page in the Dashboard.
 *
 * @component
 */

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import {
  frequentlyUsedCountries,
  nonFrequentlyUsedCountries,
} from '../../../../constants/constants';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { createShopifyUrl } from '../../../../helpers/createShopifyUrl';
import { trackFSEvent } from '../../../../helpers/fullstory';
import ReturnCusomizeQuery from './components/ReturnCustomizeQuery';
import ReturnLabel from './components/ReturnLabel';
import ReturnMultipleAddress from './components/ReturnMultipleAddress';
import ReturnNoteCusomize from './components/ReturnNoteCustomize';
import PhotoUpload from './components/ReturnPhotoUpload';
import ReturnReasonDialog from './components/ReturnReasonDialog';
import ReturnTemplate from './components/ReturnTemplateDialog';

export const FormItem = Form.Item;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

class Settings extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      returnReasons: undefined,
      shuffleReturnReasons: false,
      excludedProductTags: undefined,
      refundToCredit: true,
      refundToOriginal: true,
      refundToExchange: true,
      returnOptionAdvancedExchange: false,
      refundToCustom1: false,
      refundToCustom2: false,
      refundToCustom3: false,
      refundToCustom1Label: undefined,
      refundToCustom2Label: undefined,
      refundToCustom3Label: undefined,
      customOptionsCount: 0,
      returnTimeframe: 30,
      allowCustomDeliveryDate: true,
      returnAddress: undefined,
      returnPolicy: undefined,
      returnInstructions: undefined,
      autoAccept: false,
      methodLabelCreation: false,
      methodLabelManual: false,
      methodCustomerChoice: false,
      methodInStore: false,
      receiverName: undefined,
      receiverCompany: undefined,
      receiverStreet1: undefined,
      receiverStreet2: undefined,
      receiverCity: undefined,
      receiverState: undefined,
      receiverZip: undefined,
      receiverCountry: undefined,
      receiverPhone: undefined,
      receiverEmail: undefined,
      restockingFeeEnabled: undefined,
      restockingFeeValue: undefined,
      labelFeeEnabled: undefined,
      labelFeeValue: undefined,
      allowOrderUnfulfilled: false,
      allowOrderPaymentPending: false,
      allowOrderPaymentAuthorized: false,
      allowMultipleReturns: false,
      customerPhotoUploadEnabled: undefined,
      customerPhotoUploadRequired: undefined,
      customerPhotoUploadDescription: undefined,
      vendorList: undefined,
      showModal: false,
      showConfigPhotoUploadModel: false,
      ModalText: 'Content of the modal',
      visible: false,
      selectedStoreAddress: null,
      updateProps: false,
      restockingFeeType: 'fixed',
      restockingFeeIncludeTax: false,
      modalVisible: false,
      useGiftApiForStoreCredit: false,
      useDiscountCodeForStoreCredit: false,
      useStoreCredit: false,
      allScopesIncluded: undefined,
      storeCreditIncluded: undefined,
      shopifyPlanName: undefined,
      shopifyUrl: undefined,
      storeCreditShopifyUrl: undefined,
      enableHtmlEmailTemplates: undefined,
      migrateHtmlContent: undefined,
      returnOptionStoreWideExchange: false,
      updateReturnResolution: false,
      modalCustomize: false,
      modalCustomQuery: false,
      indexModalCustomQuery: null,
      additionalFields: [],
      enteredExcludeProductTags: '',
      trackingChange: {},
      upsEnabled: undefined,
      loading: true,
      enableConvenience: false,
      enableGiftReturns: false,
    };
  }

  handleAddCustomOption = () => {
    if (this.state.customOptionsCount < 3) {
      this.setState((prevState) => ({
        customOptionsCount: prevState.customOptionsCount + 1,
      }));
    }
  };

  handleCustomOptionChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  renderCustomOptions = () => {
    const { getFieldDecorator } = this.props.form;
    const customOptions = [];

    for (let i = 1; i <= 3; i++) {
      customOptions.push(
        <div key={i} className="flex-row flex-row--noMargin u-width--100">
          <Form.Item>
            {getFieldDecorator(
              `refundToCustom${i}`,
              {}
            )(
              <Checkbox
                checked={this.state[`refundToCustom${i}`]}
                name={`refundToCustom${i}`}
                onChange={this.handleCustomOptionChange}
                style={{ marginBottom: 10 }}
              >
                Custom option {i}
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(`refundToCustom${i}Label`, {
              initialValue: this.state[`refundToCustom${i}Label`],
            })(
              <Input
                placeholder="Add text that is shown to the customer"
                name={`refundToCustom${i}Label`}
                onChange={this.handleCustomOptionChange}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>
        </div>
      );
    }

    return customOptions;
  };

  showAddressModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  getReturnSettings = async () => {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        return;
      }
      return response.data;
    } catch (err) {
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  };
  async componentDidMount() {
    try {
      if (this.context.featureMultipleAddress) {
        this.setState({
          updateProps: true,
        });
      }

      const data = await Promise.all([
        this.getReturnSettings(),
        this.checkScopesAndPlan(),
      ]);
      const access_scopes = data[1]?.access_scopes
      await this.generateShopifyUrl(access_scopes);
      let customOptionsCount = 0;
      if (this.state.refundToCustom1 || this.state.refundToCustom1Label)
        customOptionsCount = 1;
      if (this.state.refundToCustom2 || this.state.refundToCustom2Label)
        customOptionsCount = 2;
      if (this.state.refundToCustom3 || this.state.refundToCustom3Label)
        customOptionsCount = 3;
      this.setState({
        ...data[0],
        ...data[1],
        customOptionsCount,
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your settings. Try again.', 5);
    }
  }

  generateShopifyUrl = async (access_scopes) => {
    const shopifyUrl = await createShopifyUrl('/dashboard/settings', access_scopes);
    this.setState({ 
      ...shopifyUrl, 
    });
  };

  checkScopesAndPlan = async () => {
    try {
      const response = await axios.get('/api/returnSettings/scopes');
      if (response.data == null) {
        return;
      }
      return response.data;
    } catch (err) {
      message.error('Error retrieving access scopes and plan. Try again.', 5);
    }
  };

  showConfigureModal = (showModal = true) => {
    this.setState({
      showModal: showModal,
    });
  };

  showConfigPhotoUploadModel = (showConfigPhotoUploadModel = true) => {
    this.setState({
      showConfigPhotoUploadModel: showConfigPhotoUploadModel,
    });
  };

  showReturnModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
      modalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  //Handle open return template
  showTemplateModal = () => {
    this.setState({
      modalTemplate: true,
    });
  };

  handleCancelModalTemplate = () => {
    this.setState({
      modalTemplate: false,
    });
  };

  //Handle open note customize
  showCustomizeNoteModal = () => {
    this.setState({
      modalCustomize: true,
    });
  };

  handleModalCustomize = () => {
    this.setState({
      modalCustomize: false,
    });
  };

  handleCancelModalCustomize = () => {
    this.setState({
      modalCustomize: false,
    });
  };

  //Handle open customize query
  showCustomizeQueryModal = (position) => {
    this.setState({
      modalCustomQuery: true,
      indexModalCustomQuery: position === null ? null : position,
    });
  };

  handleCustomizeQueryModal = () => {
    this.setState({
      modalCustomQuery: false,
    });
  };

  handleCancelCustomizeQueryModal = () => {
    this.setState({
      modalCustomQuery: false,
    });
  };

  updateState = (newState) => {
    this.setState({
      ...newState,
    });
  };

  removeStringWhitespace = (strParam) => {
    if (strParam == null || strParam === '') {
      return null;
    }
    let strArray = strParam.split('\n');
    strArray = strArray.filter((str) => {
      str = str.trim();
      if (str != '') return true;
      return false;
    });
    return strArray.join('\n');
  };

  updateStoreAddress = async (id, values) => {
    try {
      const response = await axios.post(
        '/api/returnSettings/multipleAddress/update',
        {
          id,
          values,
        }
      );

      if (response.data.status === 'error') {
        return message.error(
          'Error, Unable to add store address, Please try again.',
          5
        );
      }
    } catch (err) {
      return message.error(
        'Error, Unable to update store address, Please try again.',
        5
      );
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      this.trackingEvents(values);
      try {
        const excludedProductTags = this.removeStringWhitespace(
          values.excludedProductTags
        );
        const returnReasons = this.removeStringWhitespace(values.returnReasons);

        this.props.form.setFieldsValue({
          ['excludedProductTags']: excludedProductTags,
          ['returnReasons']: returnReasons,
        });

        if (this.context.featureMultipleAddress) {
          this.updateStoreAddress(this.state.selectedStoreAddress, values);
        }

        const response = await axios.post('/api/returnSettings/update', {
          ...values,
          excludedProductTags,
          returnReasons,
        });
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your return settings. Please try again-----.',
            5
          );
        }
        this.setState({
          ...response.data,
        });
        return message.success('Return settings successfully updated.', 5);
      } catch (err) {
        return message.error(
          'Error updating your return settings. Please try again.',
          5
        );
      }
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    const targetOptions = [
      'refundToCredit',
      'refundToOriginal',
      'refundToExchange',
      'returnOptionAdvancedExchange',
      'returnOptionStoreWideExchange',
    ];
    const targetReturnMethod = [
      'methodLabelCreation',
      'methodLabelManual',
      'methodCustomerChoice',
      'methodInStore',
    ];
    const returnOptionChange = targetOptions.includes(target);
    const returnMethodChange = targetReturnMethod.includes(target);
    const targetEnableCustomerUploadPhoto =
      target === 'customerPhotoUploadEnabled';
    const restockingFeeEnabled = target === 'restockingFeeEnabled';
    const labelFeeEnabled = target === 'labelFeeEnabled';
    const allowForUnpaidAndUnfulfilledOrders = [
      'allowOrderUnfulfilled',
      'allowOrderPaymentPending',
      'allowOrderPaymentAuthorized',
      'allowMultipleReturns',
    ].includes(target);
    this.setState({
      [target]: e.target.checked,
      trackingChange: {
        ...this.state.trackingChange,
        returnOption: returnOptionChange,
        customerPhotoUploadEnabled: targetEnableCustomerUploadPhoto,
        returnMethodChange,
        restockingFeeEnabled,
        labelFeeEnabled,
        allowForUnpaidAndUnfulfilledOrders,
      },
    });
  };

  handleRestockingFeeType = (value) => {
    this.setState({
      restockingFeeType: value,
    });
  };
  getCountriesList = () => {
    let countriesList = [];
    frequentlyUsedCountries.forEach((country) => {
      countriesList.push(
        <Option
          value={country.code}
          key={`frequentlyUsedCountries${country.code}`}
        >
          {country.name}
        </Option>
      );
    });

    const disabledoption = (
      <Option value="disabled" key={'disabled'} disabled={true}>
        ---------
      </Option>
    );
    countriesList.push(disabledoption);
    nonFrequentlyUsedCountries.forEach((country) => {
      countriesList.push(
        <Option
          value={country.code}
          key={`nonFrequentlyUsedCountries${country.code}`}
        >
          {country.name}
        </Option>
      );
    });
    return countriesList;
  };

  handleStateValidator = (rule, value, callback) => {
    try {
      const receiverCountry = this.props.form.getFieldValue('receiverCountry');
      if (!value && receiverCountry === 'US') {
        callback('This field is required for United States');
      } else {
        callback();
      }
    } catch (err) {
      callback(err);
    }
  };

  handleChangeMultipleAddress = (id, storeAddresses) => {
    this.setState({
      selectedStoreAddress: id,
    });

    const storeAddress = storeAddresses.filter((e) => {
      return e.id === id;
    });
    storeAddress.map((address) => {
      this.props.form.setFieldsValue({
        receiverName: address.fullName,
        receiverCompany: address.company,
        receiverStreet1: address.streetAddress,
        receiverStreet2: address.optional,
        receiverCountry: address.country,
        receiverCity: address.city,
        receiverZip: address.zipCode,
        receiverEmail: address.email,
        receiverPhone: address.phone,
      });
    });
  };

  migrateHtmlContent = async () => {
    try {
      const response = await axios.post('/api/returnSettings/migrateData');
      if (response.status != 200) {
        return message.error(
          'Error, Unable to migrate html data, Please try again.',
          5
        );
      }
      this.setState({
        migrateHtmlContent: true,
      });
      return message.success('Data migrated successfully.', 5);
    } catch (err) {
      return message.error(
        'Error, Unable to migrate html data, Please try again.',
        5
      );
    }
  };

  onChangeEnteredExcludeProductTags = (e) => {
    this.setState({ enteredExcludeProductTags: e.target.value });
  };

  trackingEvents = (values) => {
    trackFSEvent('Change Return settings', {
      feature: 'Return Settings',
      ...values,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const additionalFields = this.state.additionalFields || [];

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit} className="removeFormMargin">
          <PageButtonHeader headingTitle={'Returns'}
            headingRightContent={
              <Button
                type="primary"
                onClick={this.handleSubmit.bind(this)}
              >
                Save changes
              </Button>
            }
          ></PageButtonHeader>
          <Row
            type="flex"
            justify="start"
            align="top"
            style={{ marginBottom: 20 }}
          >
            <Col>
              <p>
                Set your return policy and define resolution options as well as
                details of the shipping process.
              </p>
            </Col>
          </Row>
          <div className="flex-row u-marginBottom--lg">
            <div className="flex-col-xxs-12">
              <Alert
                message="Important"
                description={
                  <span>
                    Set a rule for your business and staff to{' '}
                    <strong>NOT delete products</strong> from your Shopify Store
                    for orders that are still eligible for return. Deleting a
                    product will remove the associated product data from all
                    existing orders in your Shopify Store. Your customers will
                    not be able to start a return for orders with invalid
                    order-data. Shopify offers functionality to archive products
                    instead of deleting them.
                  </span>
                }
                type="info"
                showIcon
              />
            </div>
          </div>
          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Return options</h4>
              <span>
                Select possible resolution options that suit your business case.
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <h4 style={{ marginBottom: 20 }}>Set return resolution type</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gridRowGap: 20 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'refundToCredit',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.refundToCredit}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Refund to store credit
                      </Checkbox>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator(
                      'refundToOriginal',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.refundToOriginal}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Refund to original payment method
                      </Checkbox>
                    )}
                  </FormItem>
                  <FormItem>
                    <div className="u-marginBottom--sm">
                      {getFieldDecorator(
                        'refundToExchange',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.refundToExchange}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Variant Exchange
                        </Checkbox>
                      )}
                    </div>
                    {this.context.featureExchangeSelection ? (
                      <Button href="/dashboard/settings/exchanges">
                          Configure Variant-Exchange
                      </Button>
                    ) : null}
                    
                  </FormItem>
                  {this.context.featureAdvancedExchanges ? (
                    <FormItem>
                      <div className="u-marginBottom--sm">
                        {getFieldDecorator(
                          'returnOptionAdvancedExchange',
                          {}
                        )(
                          <Checkbox
                            checked={this.state.returnOptionAdvancedExchange}
                            onChange={(e) => this.handleChange(e)}
                          >
                            Collection Exchange
                          </Checkbox>
                        )}
                      </div>
                      <Button href="/dashboard/settings/exchanges/advanced">
                        Configure Collection-Exchange
                      </Button>
                    </FormItem>
                  ) : null}
                  {this.context.featureStoreWideExchange ? (
                    <FormItem>
                      <div className="u-marginBottom--sm">
                        {getFieldDecorator(
                          'returnOptionStoreWideExchange',
                          {}
                        )(
                          <Checkbox
                            checked={this.state.returnOptionStoreWideExchange}
                            onChange={(e) => this.handleChange(e)}
                          >
                            Store-Wide Exchange
                          </Checkbox>
                        )}
                      </div>
                      <Button href="/dashboard/settings/exchanges/storeWideExchange">
                          Configure Store-Wide-Exchange
                      </Button>
                    </FormItem>
                  ) : null}
                  <div>
                    <h4>Set up custom return options</h4>
                    <p>
                      If you want your customers to select a return type that
                      isn't currently available, you can add a custom return
                      option here. You can add up to three custom options.
                    </p>
                    <p className="u-marginBottom--none">
                      Please note that you can use the{' '}
                      <Link to="/dashboard/settings/rules">advanced rules</Link>{' '}
                      to customize the flow of the options you add.
                    </p>
                  </div>
                  {this.renderCustomOptions()}
                  <Form.Item>
                    {/* <Button // Disabled for now
                      type="ghost"
                      onClick={this.handleAddCustomOption}
                      disabled={this.state.customOptionsCount === 3}
                    >
                      Add Custom Option
                    </Button> */}
                  </Form.Item>
                  {/* <FormItem>
                    {getFieldDecorator(
                      'refundToCustom1',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.refundToCustom1}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Custom Option
                      </Checkbox>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('refundToCustom1Label', {
                      initialValue: this.state.refundToCustom1Label,
                    })(
                      <Input
                        addonBefore="Text for customer"
                        placeholder="Add text that is shown to the customer"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator(
                      'refundToCustom2',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.refundToCustom2}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Custom Option
                      </Checkbox>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('refundToCustom2Label', {
                      initialValue: this.state.refundToCustom2Label,
                    })(
                      <Input
                        addonBefore="Text for customer"
                        placeholder="Add text that is shown to the customer"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator(
                      'refundToCustom3',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.refundToCustom3}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Custom Option
                      </Checkbox>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('refundToCustom3Label', {
                      initialValue: this.state.refundToCustom3Label,
                    })(
                      <Input
                        addonBefore="Text for customer"
                        placeholder="Add text that is shown to the customer"
                      />
                    )}
                  </FormItem> */}
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Gift Exchange</h4>
              <span>
                Enable Gift Exchange will allow users to return products as a gift.
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gridRowGap: 20 }}
                >
                  <FormItem>
                    {getFieldDecorator('enableGiftReturns', {
                    })(
                      <Checkbox checked={this.state.enableGiftReturns} onChange={(e) => this.handleChange(e)}>Enable Gift Exchange</Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>
          
          {this.context.featureUpdateResoultion && (
            <React.Fragment>
              <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                <div className="flex-col-md-3 flex-col-xxs-12">
                  <h4>Enable ResolutionHub</h4>
                  <span>
                    Check this box if you want to be able to update the return
                    resolution of a product in a return. This can be done in the{' '}
                    <b>Manage</b> section.
                    <br />
                    <br />
                    <strong>IMPORTANT: </strong>only applicable for pending
                    returns.
                  </span>
                </div>
                <div className="flex-col-md-8 flex-col-xxs-12">
                  <div className="ContentPanel">
                    <div className="flex-row flex-row--noMargin">
                      <h4>Enable ResolutionHub</h4>
                      <FormItem>
                        {getFieldDecorator(
                          'updateReturnResolution',
                          {}
                        )(
                          <Checkbox
                            checked={this.state.updateReturnResolution}
                            onChange={(e) => this.handleChange(e)}
                          >
                            Enable to update the return resolution
                          </Checkbox>
                        )}
                      </FormItem>
                    </div>
                  </div>
                </div>
              </div>
              <Divider></Divider>
            </React.Fragment>
          )}

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Return reasons</h4>
              <span>
                Enter as many reasons as you like. Now you can also add
                translation for reasons.
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div className="flex-row flex-row--noMargin">
                  <Button type="ghost" onClick={this.showReturnModal}>
                    Manage Return Reasons
                  </Button>
                  {this.state.modalVisible ? (
                    <ReturnReasonDialog
                      visible={this.state.modalVisible}
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      featureMultilangReturnReasons={
                        this.context.featureMultilangReturnReasons
                      }
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Shuffle return reasons</h4>
              <span>
                Randomize the order of return reasons for each item on the
                returns-portal to guard against possible selection bias from the
                customer.
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div className="flex-row flex-row--noMargin">
                  <h4>Shuffle return reasons</h4>
                  <FormItem>
                    {getFieldDecorator(
                      'shuffleReturnReasons',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.shuffleReturnReasons}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable to shuffle return reasons
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Customize customer journey</h4>
              <span>
                Create custom queries to communicate with your customers. You
                can make the customer notes section optional or required. You
                can also add additional questions and create checkboxes or
                radio-buttons to get the information you need from your
                customers.
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div className="flex-row flex-row--noMargin">
                  <Col span={24}>
                    <h4>Customize notes</h4>
                    <Row
                      className="u-marginBottom--md u-rowGap--sm"
                      type="flex"
                      justify="space-between"
                      align="middle"
                    >
                      <Col>
                        <p className="u-marginBottom--sm">If you would like to edit customer notes, click the Customize button.</p>
                      
                        {this.state.modalCustomize ? (
                          <ReturnNoteCusomize
                            visible={this.state.modalCustomize}
                            onOk={this.handleModalCustomize}
                            onCancel={this.handleCancelModalCustomize}
                          />
                        ) : null}
                      
                        <Button
                          type="custom-button"
                          onClick={this.showCustomizeNoteModal}
                        >
                          Customize
                        </Button>
                      </Col>
                    </Row>

                    {additionalFields && additionalFields.length > 0 && (
                      <React.Fragment>
                        <h4>Questions</h4>
                        <Row type="flex" className="u-width--100 u-marginBottom--md u-rowGap--sm">
                          {additionalFields.map((item, index) => {
                            return (
                              <Row
                                key={index}
                                type="flex"
                                justify="space-between"
                                align="middle"
                                className="u-rowGap--sm u-width--100"
                              >
                                {this.state.modalCustomQuery &&
                                this.state.indexModalCustomQuery === index ? (
                                    <ReturnCusomizeQuery
                                      position={index}
                                      visible={this.state.modalCustomQuery}
                                      onOk={this.handleCustomizeQueryModal}
                                      onCancel={
                                        this.handleCancelCustomizeQueryModal
                                      }
                                      additionalFields={additionalFields}
                                    />
                                  ) : null}
                                <Col style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                  Q{index + 1}: Type: {item.type}. Title:{' '}
                                  {item.title}
                                </Col>
                                <Col
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                  }}
                                >
                                  <Button
                                    className="custom-button"
                                    onClick={() => {
                                      this.showCustomizeQueryModal(index);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </Col>
                              </Row>
                            );
                          })}
                        </Row>
                      </React.Fragment>
                    )}
                    <h4>Add new questions</h4>
                    <Row
                      className="u-marginBottom--md u-rowGap--sm"
                      type="flex"
                      justify="space-between"
                      align="middle"
                    >
                      <Col>
                        <p className="u-marginBottom--sm">
                        If you would like to include multiple questions in your customer's journey, click the Add button.
                        </p>
                      
                        {this.state.modalCustomQuery &&
                        (this.state.indexModalCustomQuery === null ||
                          this.state.indexModalCustomQuery === undefined) ? (
                            <ReturnCusomizeQuery
                              visible={this.state.modalCustomQuery}
                              onOk={this.handleCustomizeQueryModal}
                              onCancel={this.handleCancelCustomizeQueryModal}
                              additionalFields={additionalFields}
                            />
                          ) : null}
                        <Row
                          style={{ display: 'flex', justifyContent: 'flex-start' }}
                        >
                          <Button
                            type="ghost"
                            onClick={() => {
                              this.showCustomizeQueryModal(null);
                            }}
                            disabled={additionalFields.length >= 5}
                          >
                            <span>Add</span>
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {additionalFields.length}/5 questions already added
                    </Row>
                  </Col>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <Row
            type="flex"
            justify="space-around"
            align="middle"
            className="u-marginBottom--md"
          >
            <Col span={24}>
              <Alert
                message="How to set up automated return labels?"
                description={
                  <span>
                    We cover the setup of automated return labels{' '}
                    <a
                      href="https://support.richreturns.io/en/articles/9272445-how-to-set-up-automated-return-labels"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here in our Help Docs
                    </a>
                  </span>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>
          <Divider></Divider>

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Return methods</h4>
              <span>Select at least one return method.</span>
              <span>
                (Return labels are optional) After you set up automated return
                labels with a supported carrier they will be sent out via email
                to the customer once the return has been approved. You can find
                the corresponding email templates here:{' '}
                <a href="/dashboard/email">email template</a>.
              </span>
              <span>
                <strong> IMPORTANT: </strong> only enable <strong>one</strong> of
                the pre-paid label options (automatic or manual).
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gridRowGap: 20 }}
                >
                  <div className="flex-row flex-row--noMargin u-width--100">
                    <h4>Set pre-paid label type</h4>
                    <FormItem>
                      <div className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs u-rowGap--sm u-marginBottom--md">
                        {getFieldDecorator(
                          'methodLabelCreation',
                          {}
                        )(
                          <Checkbox
                            checked={this.state.methodLabelCreation}
                            onChange={(e) => this.handleChange(e)}
                          >
                            <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--sm u-rowGap--sm">
                              Pre-Paid Return Label (automatic)
                              <Tooltip title="This option uses our integration with Shippo to automatically generate the label. The label is shown directly to the customer in the return-portal (if you have the auto-approve option enabled) and you can automatically send the label and tracking-link to the customer in the 'approved' email template (make sure to include the dynamic variables for label and tracking).">
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          </Checkbox>
                        )}
                      </div>
                      {/* <div className="u-marginBottom--md">
                        <h4>Configure pre-paid labels</h4>
                        <Button onClick={this.showConfigureModal}>
                          Setup
                        </Button>
                        {this.state.showModal && (
                          <ReturnLabel
                            key="returnLabel"
                            showConfigureModal={this.showConfigureModal}
                            state={this.state}
                            setParentState={(values) =>
                              this.setState({ ...values })
                            }
                          />
                        )}
                      </div> */}

                    </FormItem>
                    <FormItem>
                      {getFieldDecorator(
                        'methodLabelManual',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.methodLabelManual}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--sm u-rowGap--sm">
                            Pre-Paid Return Label (manual)
                            <Tooltip title="With this option you have to manually send your label to the customer. In the 'Pending' email template you can tell the customer that you will send him/her the label via email after you have reviewed the return. Then send them the label via email manually.">
                              <Icon type="info-circle" />
                            </Tooltip>
                          </div>
                        </Checkbox>
                      )}
                    </FormItem>
                    {/* <div className="flex-row flex-row--noMargin u-rowGap--sm u-width--100">
                    </div> */}
                  </div>
                  <div className="flex-row flex-row--noMargin u-width--100">
                    <h4>Set customer return responsibility</h4>
                    <FormItem>
                      {getFieldDecorator(
                        'methodCustomerChoice',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.methodCustomerChoice}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Customer is responsible for Return Shipping
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                  <div className="flex-row flex-row--noMargin u-width--100">
                    <h4>Set as in-store return</h4>
                    <FormItem>
                      {getFieldDecorator(
                        'methodInStore',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.methodInStore}
                          onChange={(e) => this.handleChange(e)}
                        >
                          In-Store Return
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
                <h4 className="u-marginBottom--none">
                  Return timeframe (in days)
                </h4>
                <Tooltip
                  placement="top"
                  title="Ensure your store updates order fulfillment status correctly. If no 'delivered' event is found, we default to the last fulfillment update, so consider adding a few buffer days."
                >
                  <Icon type="question-circle" />
                </Tooltip>
              </div>
              <span>
                How long should customers be able to return products after the
                order has been delivered?
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div className="flex-row flex-row--noMargin">
                  <h4>Enter duration (in days)</h4>
                  <FormItem>
                    {getFieldDecorator('returnTimeframe', {
                      initialValue: this.state.returnTimeframe,
                      rules: [
                        {
                          required: true,
                          message:
                            'Please enter the timeframe for customers to make returns.',
                        },
                      ],
                    })(<InputNumber min={0} style={{ width: '100%' }} />)}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Automatically accept returns</h4>
              <span>
                Check this box if you want to automatically accept a return
                initiated by a customer. If you enabled sending an email to the
                customer under "Email settings" this will trigger the "Approved"
                Email if activated (and skip the "Pending" email).
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div className="flex-row flex-row--noMargin">
                  <h4>Automatically accept returns</h4>
                  <FormItem>
                    {getFieldDecorator(
                      'autoAccept',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.autoAccept}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable to automatically accept returns
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Excluded product-tags (optional)</h4>
              <span>
                Exclude certain products from being eligible for return based on
                one or more product-tags that you set on your Shopify
                product-page. One product-tag per line (no comma in between).
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div className="flex-row flex-row--noMargin">
                  <h4>List product tags below</h4>
                  <FormItem>
                    {getFieldDecorator('excludedProductTags', {
                      initialValue: this.state.excludedProductTags,
                    })(
                      <TextArea
                        rows={6}
                        placeholder="Enter 1 product-tag per line (no comma in between)"
                        onChange={this.onChangeEnteredExcludeProductTags}
                      />
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <React.Fragment>
            <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
              <div className="flex-col-md-3 flex-col-xxs-12">
                <h4>Enable the Convenience Return Template</h4>
                <span>
                  Manage the convenience return template to make the return
                  process quicker and more convenient.
                </span>
              </div>
              <div className="flex-col-md-8 flex-col-xxs-12">
                <div className="ContentPanel">
                  <div className="flex-row flex-middle-xxs flex-start-xxs u-rowGap--sm">
                    <div className="flex-col-xxs-12">
                      <h4>Enable template</h4>
                      <FormItem>
                        {getFieldDecorator(
                          'enableConvenience',
                          {}
                        )(
                          <Checkbox
                            checked={this.state.enableConvenience}
                            onChange={(e) => this.handleChange(e)}
                          >
                            Enable convenience return template
                          </Checkbox>
                        )}
                      </FormItem>
                    </div>
                    <div className="flex-col-xxs-12">
                      <Button
                        type="ghost"
                        onClick={this.showTemplateModal}
                        disabled={!this.state.enableConvenience}
                      >
                        Configure
                      </Button>
                    </div>
                    {this.state.modalTemplate ? (
                      <ReturnTemplate
                        visible={this.state.modalTemplate}
                        onOk={this.handleOk}
                        onCancel={this.handleCancelModalTemplate}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
          <Divider></Divider>

          {
            <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
              {!this.state.migrateHtmlContent ? (
                <React.Fragment>
                  <div className="flex-col-md-3 flex-col-xxs-12">
                    <h4>Enable HTML email template</h4>
                    <span>Click to migrate HTML content</span>
                  </div>
                  <div className="flex-col-md-8 flex-col-xxs-12">
                    <div className="ContentPanel">
                      <div className="flex-row flex-row--noMargin flex-middle-xxs">
                        <Button
                          type="ghost"
                          onClick={this.migrateHtmlContent}
                          style={{ marginRight: '10px' }}
                        >
                          Migrate HTML Content
                        </Button>
                        <Tooltip
                          placement="bottom"
                          title="This is a one time thing, it will migrate all email content to HTML in new columns. By migrating the email content in this way, we ensure that the text and formatting of your emails remain intact without any issues.
                      However, please note that you have the flexibility to modify the email format at any time through the email settings. Once you click on 'Migrate HTML Content,' a checkbox will appear, allowing you to effortlessly switch between the current simple text email format and the new HTML email format."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="flex-col-md-3 flex-col-xxs-12">
                    <h4>Enable HTML email template</h4>
                    <span>
                      Check this box if you want to enable html email template.
                    </span>
                  </div>
                  <div className="flex-col-md-8 flex-col-xxs-12">
                    <div className="ContentPanel">
                      <div className="flex-row flex-row--noMargin">
                        <h4>Enable template</h4>
                        <FormItem>
                          {getFieldDecorator(
                            'enableHtmlEmailTemplates',
                            {}
                          )(
                            <Checkbox
                              checked={this.state.enableHtmlEmailTemplates}
                              onChange={(e) => this.handleChange(e)}
                            >
                              Enable HTML template
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          }
          <Divider></Divider>
          
           <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Fees</h4>
              <span>
                Any fees set here have to be deducted from the refund. If you want to 
                charge customers during the returns process, you can use our Stripe Integration. Learn more{' '}
                <a
                  href="https://support.richreturns.io/en/articles/9272426-can-customers-pay-for-their-own-label"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>.
              </span>
              {this.state.restockingFeeType === 'percentage' && (
                <div>
                  <span>
                    Enable checkbox if you want restocking percentage for total
                    amount include tax
                  </span>
                </div>
              )}
              <div>
                <span>
                Restocking Fee: The restocking fee you set here will be displayed to the customer during the returns process. 
                This fee will be deducted from the final refund amount.               
                </span>
              </div>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gridRowGap: 20 }}
                >
                  <div className="flex-row flex-row--noMargin u-width--100">
                    <h4>Enable restocking-fee </h4>
                    <FormItem>
                      {getFieldDecorator(
                        'restockingFeeEnabled',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.restockingFeeEnabled}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Enable restocking-fee
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                  <div className="flex-row flex-row--noMargin u-width--100">
                    <h4>Set fee type</h4>
                    <div className="flex-row flex-row--noMargin u-width--100 u-rowGap--sm">
                      <FormItem>
                        {getFieldDecorator('restockingFeeType', {
                          initialValue: this.state.restockingFeeType,
                        })(
                          <Select
                            disabled={!this.state.restockingFeeEnabled}
                            onChange={this.handleRestockingFeeType.bind(this)}
                          >
                            <Option value="percentage">Percentage</Option>
                            <Option value="fixed">Fixed Amount</Option>
                          </Select>
                        )}
                      </FormItem>
                      {this.state.restockingFeeType === 'percentage' && (
                        <FormItem>
                          {getFieldDecorator(
                            'restockingFeeIncludeTax',
                            {}
                          )(
                            <Checkbox
                              checked={this.state.restockingFeeIncludeTax}
                              onChange={(e) => this.handleChange(e)}
                              disabled={!this.state.restockingFeeEnabled}
                            >
                              Enable tax based restocking-fee
                            </Checkbox>
                          )}
                        </FormItem>
                      )}
                    </div>
                  </div>
                  <div className="flex-row flex-row--noMargin u-width--100">
                    <div
                      className="flex-row flex-row--noMargin u-width--100 u-rowGap--sm u-columnGap--sm flex-start-xxs flex-middle-xxs"
                      style={{ marginBottom: 8 }}
                    >
                      <h4 className="u-marginBottom--none">{`Restocking-Fee ( in ${
                        this.state.restockingFeeType === 'fixed'
                          ? " your store's currency"
                          : ' percentage'
                      } )`}</h4>
                      {!this.state.methodCustomerPaidLabel && (
                        <Tooltip title="Set a label fee to cover prepaid shipping label costs (only applies if the prepaid-label option is enabled). The fee is deducted from the refund. Use only numbers (e.g., 4.50). Do not use commas or include the currency.">
                          <Icon type="info-circle" />
                        </Tooltip>
                      )}
                    </div>
                    <FormItem>
                      {getFieldDecorator('restockingFeeValue', {
                        initialValue: this.state.restockingFeeValue,
                      })(
                        this.state.restockingFeeType === 'fixed' ? (
                          <InputNumber
                            disabled={!this.state.restockingFeeEnabled}
                            min={0}
                            step={0.1}
                            style={{ width: '100%' }}
                          />
                        ) : (
                          <InputNumber
                            disabled={!this.state.restockingFeeEnabled}
                            min={0}
                            max={100}
                            step={0.1}
                            style={{ width: '100%' }}
                          />
                        )
                      )}
                    </FormItem>
                  </div>
                  {!this.state.methodCustomerPaidLabel && (
                    <div className="flex-row flex-row--noMargin u-width--100">
                      <div
                        className="flex-row flex-row--noMargin u-width--100 u-rowGap--sm u-columnGap--sm flex-middle-xxs"
                        style={{ marginBottom: 8 }}
                      >
                        <h4 className="u-marginBottom--none">
                          Enable pre-paid label fee
                        </h4>
                        <Tooltip
                          title="Enable this option. If you want customer select this option
                    and will be responsible for the pre-paid lebel fee."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </div>
                      <div
                        className="flex-row flex-row--noMargin u-width--100"
                        style={{ gridRowGap: 20 }}
                      >
                        <FormItem>
                          {getFieldDecorator(
                            'labelFeeEnabled',
                            {}
                          )(
                            <Checkbox
                              checked={this.state.labelFeeEnabled}
                              onChange={(e) => this.handleChange(e)}
                            >
                              Enable prepaid label-fee
                            </Checkbox>
                          )}
                        </FormItem>
                        <div className="flex-row flex-row--noMargin u-width--100">
                          <h4>Set label fee value</h4>
                          <FormItem>
                            {getFieldDecorator('labelFeeValue', {
                              initialValue: this.state.labelFeeValue,
                            })(
                              <InputNumber
                                min={0}
                                step={0.1}
                                style={{ width: '100%' }}
                                disabled={!this.state.labelFeeEnabled}
                              />
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex-row flex-row--noMargin u-width--100">
                    <h4>Set customer responsibility</h4>
                    <FormItem>
                      {getFieldDecorator(
                        'methodCustomerPaidLabel',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.methodCustomerPaidLabel}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <div className="flex-row flex-row--noMargin u-rowGap--sm u-columnGap--sm flex-middle-xxs">
                            <div>Customer is responsible for label fee</div>
                            <Tooltip
                              title="With this option customer will be responsible for the pre-paid label fees.
                        If this option is enabled then functionality for the prepiad label and fixed label amount will be disabled."
                            >
                              <Icon type="info-circle" />
                            </Tooltip>
                          </div>
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Allow for Unpaid and Unfulfilled orders</h4>
              <span>
                Most merchants <strong>do not</strong> need to change these
                settings. Please only make changes if you know how this effects
                what customers can return.
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <h4 style={{ marginBottom: 20 }}>Set customer return type</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gridRowGap: 20 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'allowOrderUnfulfilled',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.allowOrderUnfulfilled}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <div className="flex-row flex-row--noMargin u-rowGap--sm u-columnGap--sm flex-middle-xxs">
                          <div>Allow Returns for Unfulfilled Orders</div>
                          <Tooltip
                            title="Example: a merchant wants
                customers to be able to return an order as a quasi cancellation
                for their order when the order itself has not shipped, yet. Note
                that we do not cancel the order, this has to be done manually."
                          >
                            <Icon type="info-circle" />
                          </Tooltip>
                        </div>
                      </Checkbox>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator(
                      'allowOrderPaymentPending',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.allowOrderPaymentPending}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <div className="flex-row flex-row--noMargin u-rowGap--sm u-columnGap--sm flex-middle-xxs">
                          <div>Allow Returns for Pending Payments</div>
                          <Tooltip
                            title="Example: a merchant wants
                customers to be able to return an order when payment is settled
                in cash (e.g. courier) and there is a time delay in registering
                the transaction (manually) so the customer can already initiate
                a return."
                          >
                            <Icon type="info-circle" />
                          </Tooltip>
                        </div>
                      </Checkbox>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator(
                      'allowOrderPaymentAuthorized',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.allowOrderPaymentAuthorized}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <div className="flex-row flex-row--noMargin u-rowGap--sm u-columnGap--sm flex-middle-xxs">
                          <div>Allow Returns for Authorized Payments</div>
                          <Tooltip
                            title="Example: a merchant wants
                customers to be able to return an order when payment has been
                authorized on the customer's end but not captured on the
                merchant's end. A payment usually goes instantly from authorized
                to paid in Shopify."
                          >
                            <Icon type="info-circle" />
                          </Tooltip>
                        </div>
                      </Checkbox>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator(
                      'allowMultipleReturns',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.allowMultipleReturns}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <div className="flex-row flex-row--noMargin u-rowGap--sm u-columnGap--sm flex-middle-xxs">
                          <div>Allow multiple Returns</div>
                          <Tooltip
                            title="Example: you offer a lifetime
                warranty for your products to your customers. A customer has
                already received an exchange for a faulty item but a year later
                something else breaks and they need to initiate another Return.
                This will apply to all items in the order."
                          >
                            <Icon type="info-circle" />
                          </Tooltip>
                        </div>
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          {this.context.featureStoreCredit === true &&
            (!this.state.allScopesIncluded ? (
              <React.Fragment>
                <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                  <div className="flex-col-md-3 flex-col-xxs-12">
                    <h4>Enable store credit</h4>
                    <span>
                      When enabling this option, you will be redirected to
                      Shopify to configure your store credit either by a gift
                      card or discount code.
                    </span>
                  </div>
                  <div className="flex-col-md-8 flex-col-xxs-12">
                    <div className="ContentPanel">
                      <div className="flex-row flex-row--noMargin">
                        <FormItem>
                          <Button type="ghost" href={this.state.shopifyUrl}>
                            Enable scopes
                          </Button>
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {(this.state.shopifyPlanName === 'enterprise' ||
                  this.state.shopifyPlanName === 'shopify_plus') && (
                  <React.Fragment>
                    <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                      <div className="flex-col-md-3 flex-col-xxs-12">
                        <h4>Enable feature gift card</h4>
                      </div>
                      <div className="flex-col-md-8 flex-col-xxs-12">
                        <div className="ContentPanel">
                          <div className="flex-row flex-row--noMargin">
                            <FormItem>
                              {getFieldDecorator(
                                'useGiftApiForStoreCredit',
                                {}
                              )(
                                <Checkbox
                                  checked={this.state.useGiftApiForStoreCredit}
                                  onChange={(e) => this.handleChange(e)}
                                >
                                  Enable
                                </Checkbox>
                              )}
                            </FormItem>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                  <div className="flex-col-md-3 flex-col-xxs-12">
                    <h4>Enable feature discount code</h4>
                    <span>
                      To be able to send discount codes through Rich Returns, you will need to enable the 
                      scopes in your Shopify dashboard. Once the scopes are enabled, and this feature is 
                      activated by checking the box, you will see a new button appear in the Manage section 
                      where you can issue discount codes to your customers.
                    </span>
                  </div>
                  <div className="flex-col-md-8 flex-col-xxs-12">
                    <div className="ContentPanel">
                      <div className="flex-row flex-row--noMargin">
                        <FormItem>
                          {getFieldDecorator(
                            'useDiscountCodeForStoreCredit',
                            {}
                          )(
                            <Checkbox
                              checked={this.state.useDiscountCodeForStoreCredit}
                              onChange={(e) => this.handleChange(e)}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          <Divider></Divider>
          
          {this.context.featureStoreCredit === true &&
            (!this.state.storeCreditIncluded ? (
              <React.Fragment>
                <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                  <div className="flex-col-md-3 flex-col-xxs-12">
                    <h4>Enable store credit</h4>
                    <span>
                      To be able to issue Store Credit to your customers' accounts, 
                      you need to Enable Scopes in Shopify. This button will redirect 
                      you to Shopify to enable scopes. You will be able to issue store 
                      credit through Rich Returns. You can also{' '}
                      <a
                        href="https://support.richreturns.io/en/articles/9272063-how-can-i-enable-integrated-store-credit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>read our guide </strong>
                      </a>{' '}
                      for more information.
                    </span>
                  </div>
                  <div className="flex-col-md-8 flex-col-xxs-12">
                    <div className="ContentPanel">
                      <div className="flex-row flex-row--noMargin">
                        <FormItem>
                          <Button type="ghost" href={this.state.storeCreditShopifyUrl}>
                            Enable scopes
                          </Button>
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                  <div className="flex-col-md-3 flex-col-xxs-12">
                    <h4>Enable feature store credit</h4>
                    <span>
                      <strong>IMPORTANT: </strong> To allow your customers to see 
                      and spend store credit, the Store credit option in your 
                      Shopify dashboard should be enabled. You can enable it here:{' '}
                      <a 
                        href={this.context.storeShopifyUrl + "/admin/settings/customer_accounts"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Store Credit.
                      </a>
                    </span>
                  </div>
                  <div className="flex-col-md-8 flex-col-xxs-12">
                    <div className="ContentPanel">
                      <div className="flex-row flex-row--noMargin">
                        <FormItem>
                          {getFieldDecorator(
                            'useStoreCredit',
                            {}
                          )(
                            <Checkbox
                              checked={this.state.useStoreCredit}
                              onChange={(e) => this.handleChange(e)}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          <Divider></Divider>

          {this.context.featureCustomerPhotoUpload ? (
            <React.Fragment>
              <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                <div className="flex-col-md-3 flex-col-xxs-12">
                  <h4>Customer photo uploads</h4>
                  <span>
                    Let customers upload photos of their return-items. You can
                    review the photos under the <a href="/dashboard/returns">Returns</a>{' '}
                    tab by selecting one of the returns.
                  </span>
                </div>
                <div className="flex-col-md-8 flex-col-xxs-12">
                  <div className="ContentPanel">
                    <div
                      className="flex-row flex-row--noMargin"
                      style={{ gridRowGap: 20 }}
                    >
                      <FormItem>
                        {getFieldDecorator(
                          'customerPhotoUploadEnabled',
                          {}
                        )(
                          <Checkbox
                            checked={this.state.customerPhotoUploadEnabled}
                            onChange={(e) => this.handleChange(e)}
                          >
                            Enable
                          </Checkbox>
                        )}
                        <Button
                          onClick={() => this.showConfigPhotoUploadModel()}
                          disabled={!this.state.customerPhotoUploadEnabled}
                          style={{ marginTop: "10px" }}
                        >
                          Configure
                        </Button>
                        {this.state.showConfigPhotoUploadModel && (
                          <PhotoUpload
                            showConfigPhotoUploadModel={
                              this.showConfigPhotoUploadModel
                            }
                            state={this.state}
                            updateState={this.updateState}
                          ></PhotoUpload>
                        )}
                      </FormItem>
                      <FormItem label="Description text">
                        {getFieldDecorator('customerPhotoUploadDescription', {
                          initialValue:
                            this.state.customerPhotoUploadDescription,
                        })(
                          <TextArea
                            rows={6}
                            placeholder="This description will be displayed to customers alongside the photo upload. Briefly describe what they should pay attention to when uploading photos."
                          />
                        )}
                      </FormItem>
                    </div>
                  </div>
                </div>
              </div>
              <Divider></Divider>
            </React.Fragment>
          ) : null}

          {this.context.featureMultichannel ? (
            <React.Fragment>
              <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                <div className="flex-col-md-3 flex-col-xxs-12">
                  <h4>Manual Multichannel RMAs</h4>
                </div>
                <div className="flex-col-md-8 flex-col-xxs-12">
                  <div className="ContentPanel">
                    <div
                      className="flex-row flex-row--noMargin"
                      style={{ gridRowGap: 20 }}
                    >
                      <h4>Vendors</h4>
                      <span>
                        Add returns from vendors outside of Shopify. Enter as
                        many vendors as you like. One vendor per line (no comma
                        inbetween).
                      </span>
                      <FormItem>
                        {getFieldDecorator('vendorList', {
                          initialValue: this.state.vendorList,
                        })(
                          <TextArea
                            rows={6}
                            placeholder="Enter 1 vendor per line (no comma inbetween)"
                          />
                        )}
                      </FormItem>
                    </div>
                  </div>
                </div>
              </div>
              <Divider></Divider>
            </React.Fragment>
          ) : null}

          <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
            <div className="flex-col-md-3 flex-col-xxs-12">
              <h4>Return address</h4>
              <span>
                Enter the address that is shown to customers in preparation for
                sending back their package. This address will also be used if
                you have pre-paid return-labels enabled above.
              </span>
            </div>
            <div className="flex-col-md-8 flex-col-xxs-12">
              <div className="ContentPanel">
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gridRowGap: 20 }}
                >
                  <div className="u-width--100">
                    <h4>Select country</h4>
                    <FormItem>
                      {getFieldDecorator('receiverCountry', {
                        initialValue: this.state.receiverCountry,
                        rules: [{ required: true, message: 'Please select country' }],
                      })(
                        <Select placeholder="Select country">
                          {this.getCountriesList()}
                        </Select>
                      )}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>Customer name</h4>
                    <FormItem>
                      {getFieldDecorator('receiverName', {
                        initialValue: this.state.receiverName,
                        rules: [{ required: true, message: 'Please enter customer name' }],
                      })(<Input />)}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>Company name</h4>
                    <FormItem>
                      {getFieldDecorator('receiverCompany', {
                        initialValue: this.state.receiverCompany,
                      })(<Input placeholder="(Optional)" />)}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>Street address</h4>
                    <FormItem>
                      {getFieldDecorator('receiverStreet1', {
                        initialValue: this.state.receiverStreet1,
                        rules: [{ required: true, message: 'Please enter street address' }],
                      })(
                        <Input placeholder="Street and number, P.O. box, c/o." />
                      )}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>Apartment, suite, unit, building, floor, etc.</h4>
                    <FormItem>
                      {getFieldDecorator('receiverStreet2', {
                        initialValue: this.state.receiverStreet2,
                      })(
                        <Input placeholder="Apartment, suite, unit, building, floor, etc." />
                      )}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>City</h4>
                    <FormItem>
                      {getFieldDecorator('receiverCity', {
                        initialValue: this.state.receiverCity,
                        rules: [
                          { required: true }
                        ]
                      })(
                        <Input placeholder="City" />
                      )}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>State</h4>
                    <FormItem>
                      {getFieldDecorator('receiverState', {
                        initialValue: this.state.receiverState,
                        rules: [
                          { validator: this.handleStateValidator }
                        ]
                      })(
                        <Input placeholder="State / Province / Region" />
                      )}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>Zip Code</h4>
                    <FormItem>
                      {getFieldDecorator('receiverZip', {
                        initialValue: this.state.receiverZip,
                        rules: [
                          { required: true }
                        ]
                      })(
                        <Input placeholder="Zip Code" />
                      )}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>Phone number</h4>
                    <FormItem>
                      {getFieldDecorator('receiverPhone', {
                        initialValue: this.state.receiverPhone,
                        rules: [
                          { required: true }
                        ]
                      })(
                        <Input placeholder="May be used to assist delivery" />
                      )}
                    </FormItem>
                  </div>
                  <div className="u-width--100">
                    <h4>E-Mail</h4>
                    <FormItem>
                      {getFieldDecorator('receiverEmail', {
                        initialValue: this.state.receiverEmail,
                        rules: [
                          { required: true }
                        ]
                      })(
                        <Input placeholder="May be used to assist delivery" />
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>

          {this.context.featureMultipleAddress && (
            <React.Fragment>
              <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                <div className="flex-col-md-3 flex-col-xxs-12">
                  <h4>Multiple Address</h4>
                </div>
                <div className="flex-col-md-8 flex-col-xxs-12">
                  <div className="ContentPanel">
                    <div className="flex-row flex-row--noMargin">
                      <h4>Store Address</h4>
                      <p className="u-marginBottom--md">
                        You can add multiple store addresses. Selected Store
                        address that is shown to customers in preparation for
                        sending back their package. This address will also be
                        used if you have pre-paid return-labels enabled above
                      </p>
                      <ReturnMultipleAddress
                        countriesList={this.getCountriesList()}
                        handleChangeMultipleAddress={
                          this.handleChangeMultipleAddress
                        }
                        defaultActiveStoreAddress={
                          this.state.selectedStoreAddress
                        }
                        showAddressModal={this.showAddressModal}
                        visible={this.state.visible}
                        getReturnSettings={this.getReturnSettings}
                        updateProps={this.state.updateProps}
                      />
                      <div className="u-width--100 u-marginTop--md">
                        {this.context.featureMultipleAddressQuota > 0 ? (
                          <Text>
                            You have {this.context.featureMultipleAddressQuota}{' '}
                            store addresses left on your current plan.{' '}
                          </Text>
                        ) : (
                          <p>
                            <Text>
                              You have{' '}
                              {this.context.featureMultipleAddressQuota} store
                              addresses left on your current plan. Upgrade your
                              plan.
                            </Text>
                          </p>
                        )}
                      </div>
                      <Button
                        type="ghost"
                        onClick={this.showAddressModal}
                        disabled={
                          this.context.featureMultipleAddressQuota === 0
                            ? true
                            : false
                        }
                        className="u-marginTop--md"
                      >
                        Add Multiple Addresses
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          <div className="flex-row flex-row--noMargin flex-end-xxs u-marginBottom--md u-marginTop--sm">
            <FormItem>
              <Button type="primary" htmlType="submit">
                Save changes
              </Button>
            </FormItem>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedSettings = Form.create()(Settings);
export default WrappedSettings;
